import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomNoRowsOverlay from './customNoRowsOverlay';
// import "@ag-grid-community/styles/ag-theme-quartz.css";
import ShowDQMdetails from "./ShowDQMdetails";
import ShowDQMReject from "./ShowDQMReject";
import Accounttemplatestyle from "../Template/Addtemplate.module.css";
import "./FileApprovalStatus.css";
import { status_colors } from "../../constants";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import {
  Archive,
  ArrowCounterclockwise,
  CheckLg,
  Download,
  Eye,
  Upload,
  X,
} from "react-bootstrap-icons";
import ShowfileDetails from "./ShowfileDetails";
import ApprovalMessage from "../ApprovalMessage/ApprovalMessage";
import {
  fetchStatusView,
  statusUpdate,
  downloadFile,
  fetchEsignURL
} from "../Services/UserServices";
import Loader from "../Loader";
import userContext from "../../user-context";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";


const Tabcommon = (props) => {
  const gridRef = useRef();
  localStorage.removeItem("selectedtemplate");
  localStorage.setItem("showbasicfilter", false);
  // localStorage.removeItem('showbasicfilter');
  const { user } = useContext(userContext);
  localStorage.removeItem("type");
  //localStorage.removeItem('selectedtemplate');
  localStorage.removeItem("restorebasicfilter");
  localStorage.removeItem('restoreadvancefilter');
  localStorage.removeItem("selectedcheckdpartner");
  localStorage.removeItem("selectedcheckdworkflow");
  localStorage.removeItem("selectedcheckdstudy");
  localStorage.removeItem("selectedcheckdmaterial");
  localStorage.removeItem("selectedcheckdfile");
  localStorage.removeItem("selectedcheckddataset");
  localStorage.removeItem("selectedcheckdstatus");
  localStorage.removeItem("partnerlistsession");
  localStorage.removeItem("workflowlistsession");
  localStorage.removeItem("studylistsession");
  localStorage.removeItem("materiallistsession");
  localStorage.removeItem("filelistsession");
  localStorage.removeItem("datasetlistsession");
  localStorage.removeItem("statuslistsession");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowDatastatus, setRowDataStatus] = useState([]);
  const [activetab, setActivetab] = useState(true);
  const userdetailsokta = useContext(userContext);
  const [errormessage, setErrormessage] = useState("");
  const [successmessage, setSuccessmessage] = useState("");
  const [selectedata, setSeleteddata] = useState({});
  const [dqmrejectdata, setDqmrejectdata] = useState({});
  const [showDQMreject, setshowDQMreject] = useState(false);
  const [showDQM, setshowDQM] = useState(false);
  const [selecterowclickdata, setSeletedrowclickdata] = useState([]);
  const [checkboxselect, setCheckboxselect] = useState(false);
  const [errorstate, setErrorcheck] = useState(false);
  const [errorcoments, seterrorcoments] = useState(false);
  const [modalAppralOpen, setModalAppralOpen] = useState(false);
  const [show, setShowApprovalModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [checkbox, setcheckbox] = useState(false);
  const [showfile, setviewFileDetailsModal] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [esignURL, setEsignURL] = useState("");
  const [showErrorBox, toggleShowErrorBox] = useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    document.getElementById("filter-text-box").value = "";
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    if (
      localStorage.getItem("role") === "Author" ||
      localStorage.getItem("role") === "Super User"
    ) {
      setcheckbox(false);
    } else {
      setcheckbox(true);
    }
    if (activetab === true) {
      // console.log("activePageaction", 1);
      fetchStatusViewData("Actions", 1);
    } else {
      //console.log("activePage", 1);
      fetchStatusViewDataStatus("Status", 1);
    }
  }, [activetab]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,

      sortable: true,
      color: "red",

      wrapHeaderText: true,
      suppressMovable: true,
      autoHeaderHeight: true,
      //     wrapText: true,
      //    autoHeight: true,
      unSortIcon: true,
    };
  }, []);
  const handleChangePagination = (event, value) => {
    //console.log(value)
    setActivePage(value);
    if (activetab === true) {
      //// console.log("activePageaction", value);
      fetchStatusViewData("Actions", value);
    } else {
      //console.log("activePage", value);
      fetchStatusViewDataStatus("Status", value);
    }
  };
  const [columnDefs] = useState([
    {
      headerName: "File Name",
      field: "fileName",
      tooltipField: "fileName",
      // width: 100,
      // maxWidth: 210,
      checkboxSelection: true,
      hide:
        localStorage.getItem("role") === "Author" ||
          localStorage.getItem("role") === "Super User"
          ? true
          : false,
      // checkboxSelection: params => {
      //     if (localStorage.getItem("role") === 'Author' || localStorage.getItem("role") === 'Super User') {
      //         return false
      //     }
      //     else {
      //         return true
      //     }
      // },
      //width: 50,
      // maxWidth: 330,

      //wrapText: true, autoHeight: true,
      cellRenderer: function (params) {
        //console.log(params)
        // if (localStorage.getItem("role") === 'Author') {
        return (
          <>
            <a
              href="#"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              data-toggle="tooltip"
              onClick={() => viewFileDetails(params)}
              data-placement="top"
              title={params.value}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      headerName: "File Name",
      field: "fileName",
      tooltipField: "fileName",
      // width: 100,
      // maxWidth: 210,
      //checkboxSelection: true,
      hide:
        localStorage.getItem("role") === "Author" ||
          localStorage.getItem("role") === "Super User"
          ? false
          : true,
      cellRenderer: function (params) {
        //console.log(params)
        // if (localStorage.getItem("role") === 'Author') {
        return (
          <>
            <a
              href="#"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              data-toggle="tooltip"
              onClick={() => viewFileDetails(params)}
              data-placement="top"
              title={params.value}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      headerName: "File Ver.",
      field: "file_version",
      tooltipField: "file_version",
      width: 70,
      maxWidth: 92,
    },
    // {
    //     headerName: "Template Name",
    //     field: "templateName",
    //     tooltipField: "templateName",
    //     width: 10,
    //     maxWidth: 180,
    // },
    {
      headerName: "Author",
      field: "author",
      tooltipField: "author",
      width: 80,
      maxWidth: 148,
    },
    {
      headerName: "Approval Status",
      field: "status",
      tooltipField: "status",
      width: 10,
      maxWidth: 250,
      //cellRenderer: CustomButtonComponent,
      //wrapText: true, autoHeight: true,

      cellRenderer: function (params) {
        //  console.log( status_colors[params.value])
        return (
          <button
            className="btn btn-primary"
            style={{
              color: "black",
              backgroundColor: status_colors[params.value],
              border: "none",
              fontSize: "13px",
              width: "100%",
            }}
          >
            {params.value}
          </button>
        );
        // if (params.value === "Stage 1 Pending") {

        //     return (<button className="btn btn-primary" style={{ color: 'red', border: 'none', fontSize: '13px', backgroundColor: 'rgb(241 196 196)' }}>{params.value}</button>);
        //     // return ( <div style={{ color: 'red', backgroundColor: 'rgb(241 196 196)',borderRadius:'5px',paddingTop:'10px',height:'42px'}}><span >{params.value}
        //     // </span></div>);
        // }
        // else if (params.value === "File Conversion Passed") {
        //     return (<button className="btn btn-primary" style={{ color: '#156391', border: 'none', fontSize: '13px', backgroundColor: '#94c9e9' }}>{params.value}</button>);
        //     // return ( <div style={{ color: '#156391', backgroundColor: 'rgb(155 197 227)',height:'42px',borderRadius:'5px'}}><span >{params.value}
        //     // </span></div>);

        // }
        // else if (params.value === "Data Quality Check awaiting") {

        //     return (<button className="btn btn-primary" style={{ color: 'black', border: 'none', fontSize: '13px', backgroundColor: 'rgb(239 234 116)' }}>{params.value}</button>);

        // }
        // else {
        //     return <span>{params.value}</span>;
        // }
      },
      // cellStyle: function (params) {
      //     // console.log(params.value);

      //     //return <span className={params.value==='FAILED'?'text-danger':''}>{params.value}</span>
      //     if (params.value === "Stage 1 pending") {
      //         return { color: 'red', backgroundColor: '#e17c7c',marginTop:'10px' };
      //     }
      //     else if (params.value === "File Conversion Passed") {
      //         return { color: 'blue', backgroundColor: '#5ba4d9' };
      //     }
      //     else if (params.value === "Data Quality Check awaiting") {
      //         return { color: 'black', backgroundColor: '#cb969b' };
      //     }
      //     else {
      //         return <span>{params.value}</span>;
      //     }
      // },
    },
    {
      headerName: "Last Modified Date",
      field: "modifiedDate",
      width: 130,
      maxWidth: 170,
      tooltipField: "modifiedDate",
    },
    {
      headerName: "Stage 1 Approver",
      field: "stage1Approver",
      tooltipField: "stage1Approver",
      width: 80,
      maxWidth: 140,
    },
    {
      headerName: "Stage 2 Approver",
      field: "stage2Approver",
      tooltipField: "stage2Approver",
      width: 80,
      maxWidth: 140,
    },

    {
      headerName: "Action",
      field: "view",
      width: 120,
      maxWidth: 130,
      cellRenderer: function (params) {
        //console.log(params)
        // return <>

        //     <span style={{ cursor: 'pointer' }} data-toggle="tooltip" onClick={() => viewData(params)} data-action-type="view" data-placement="top" title="View Data & Supplementary Data" ><Eye size={16} data-action-type="view" /></span>
        //     <span className='pl-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" onClick={() => fileDownload(params)} title="Download"><Download size={14} data-action-type="download" /></span>
        //     <span className='pl-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Re-upload"><ArrowCounterclockwise size={14} data-action-type="Reupload" /></span>

        // </>;
        // return (
        //     params['data']['approvalStatus'] === "Data Quality Check Rejected" &&
        //     <>
        //         <span style={{ cursor: 'pointer' }} data-toggle="tooltip" onClick={() => viewData(params)} data-action-type="view" data-placement="top" title="View Data Quality Report" ><Eye size={20} data-action-type="view" /></span>
        //         <span className='pl-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Download Data Quality Report"><Download size={18} data-action-type="download" /></span>
        //     </>
        // )
        return localStorage.getItem("role") === "Author" ||
          localStorage.getItem("role") === "Super User" ? (
          <>
            <span
              className="pl-2"
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              data-placement="top"
              onClick={() => fileDownload(params, "Excel")}
              title="Download"
            >
              <Download size={14} data-action-type="download" />
            </span>
            {params["data"]["archive_rejected"] === "true" ? <span
              className="pl-2"
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Re-upload"
              onClick={() =>
                reUploadFile(params)
              }

            >
              <ArrowCounterclockwise size={14} data-action-type="Reupload" />
            </span> : null}
            {params["data"]["archive_approved"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
                onClick={() =>
                  selectedTemplateArchive("archive_approve", params)
                }
              >
                <Archive size={14} data-action-type="archive_approve" />
              </span>
            ) : null}
            {params["data"]["archive_rejected"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
                onClick={() =>
                  selectedTemplateArchive("archive_rejected", params)
                }
              >
                <Archive size={14} data-action-type="archive_rejected" />
              </span>
            ) : null}
          </>
        ) : (
          <>
            <span
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              onClick={() => viewData(params)}
              data-action-type="view"
              data-placement="top"
              title="View Data & Supplementary Data"
            >
              <Eye size={16} data-action-type="view" />
            </span>
            <span
              className="pl-2"
              style={{ cursor: "pointer" }}
              data-toggle="tooltip"
              data-placement="top"
              onClick={() => fileDownload(params, "Excel")}
              title="Download"
            >
              <Download size={14} data-action-type="download" />
            </span>

            {params["data"]["archive_approved"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
                onClick={() =>
                  selectedTemplateArchive("archive_approve", params)
                }
              >
                <Archive size={14} data-action-type="archive_approve" />
              </span>
            ) : null}
            {params["data"]["archive_rejected"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
                onClick={() =>
                  selectedTemplateArchive("archive_rejected", params)
                }
              >
                <Archive size={14} data-action-type="archive_rejected" />
              </span>
            ) : null}
          </>
        );
      },
    },
  ]);
  const [columnDefsstatus] = useState([
    {
      headerName: "File Name",
      field: "fileName",
      tooltipField: "fileName",
      // width: 10,
      // maxWidth: 330,
      // width: (localStorage.getItem("role") === 'Author' || localStorage.getItem("role") === 'Super User') ? 100 : 280,
      // maxWidth: (localStorage.getItem("role") === 'Author' || localStorage.getItem("role") === 'Super User') ? 180 : 300,

      cellRenderer: function (params) {
        //console.log(params)
        return (
          <>
            <a
              href="#"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              data-toggle="tooltip"
              onClick={() => viewFileDetails(params)}
              data-placement="top"
              title={params.value}
            >
              {params.value}
            </a>
          </>
        );
      },
    },
    {
      headerName: "File Ver.",
      field: "file_version",
      tooltipField: "file_version",
      width: 70,
      maxWidth: 92,
    },
    // {
    //     headerName: "Template Name",
    //     field: "templateName",
    //     tooltipField: "templateName",
    //     width: 10,
    //     maxWidth: 178,
    // },
    {
      headerName: "Author",
      field: "author",
      tooltipField: "author",
      width: 60,
      maxWidth: 145,
    },
    {
      headerName: "Approval Status",
      field: "status",
      tooltipField: "status",
      width: 10,
      maxWidth: 250,
      //cellRenderer: CustomButtonComponent,
      //wrapText: true, autoHeight: true,

      cellRenderer: function (params) {
        //console.log(params)
        //console.log(status_colors[params.value])
        return (
          <button
            className="btn btn-primary"
            style={{
              width: "100%",
              color: "black",
              backgroundColor: status_colors[params.value],
              border: "none",
              fontSize: "13px",
            }}
          >
            {params.value}
          </button>
        );
      },
    },
    {
      headerName: "Last Modified Date",
      field: "modifiedDate",
      width: 130,
      maxWidth: 170,
      tooltipField: "modifiedDate",
    },
    {
      headerName: "Stage 1 Approver",
      field: "stage1Approver",
      tooltipField: "stage1Approver",
      width: 70,
      maxWidth: 150,
    },
    {
      headerName: "Stage 2 Approver",
      field: "stage2Approver",
      tooltipField: "stage2Approver",
      width: 70,
      maxWidth: 140,
    },

    {
      headerName: "View/ Download ",
      field: "view",
      width: 120,
      maxWidth: 130,
      suppressToolPanel:
        localStorage.getItem("role") === "Author" ||
          localStorage.getItem("role") === "Super User"
          ? false
          : true,
      hide:
        localStorage.getItem("role") === "Author" ||
          localStorage.getItem("role") === "Super User"
          ? false
          : true,
      cellRenderer: function (params) {
        //console.log(params)
        return (
          <>
            {params["data"]["archive_approved"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
              >
                <Archive size={14} data-action-type="archive_approve" />
              </span>
            ) : null}
            {params["data"]["archive_rejected"] === "true" ? (
              <span
                className="pl-2"
                style={{ cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Archive"
              >
                <Archive size={14} data-action-type="archive_rejected" />
              </span>
            ) : null}

            {/* {params['data']['approvalStatus'] === "Data Quality Check Rejected" &&
                            <>
                                <span style={{ cursor: 'pointer' }} data-toggle="tooltip" onClick={() => viewDataQuality(params)} data-action-type="view" data-placement="top" title="View Data Quality Report" ><Eye size={20} data-action-type="view" /></span>
                                <span className='pl-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Download Data Quality Report"><Download size={18} data-action-type="download" /></span>
                            </>
                        } */}
            {params["data"]["dqm_report"] === "true" && (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  data-toggle="tooltip"
                  onClick={() => viewDataQuality(params)}
                  data-action-type="view"
                  data-placement="top"
                  title="View Data Quality Report"
                >
                  <Eye size={20} data-action-type="view" />
                </span>
                <span
                  className="pl-2"
                  style={{ cursor: "pointer" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={() => fileDownload(params, "DQM")}
                  title="Download Data Quality Report"
                >
                  <Download size={18} data-action-type="download" />
                </span>
              </>
            )}
          </>
        );
      },
    },
    // {
    //     headerName: "Comments",
    //     field: "comments",
    //     tooltipField: "comments",
    // },
    // {
  ]);
  // const onGridReady = useCallback((params) => {

  //     fetchStatusViewData('Actions')
  //     fetchStatusViewDataStatus('Status')

  //     // const data = [
  //     //     { filename: "DPC_AMG510_acryloylchloridedistillation_Copy", fileversion: "1",templatename:"DPC_AMG510_acryloylchlor", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'chalde01', stage2approver: 'chalde01', comments: 'hiii' },

  //     //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
  //     //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
  //     //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },
  //     //     { filename: "AMG2309_AUDITCHECK_AMGEN_DPC_MES_QC.xlsx",approvedDt:"2023-08-23 06:53:37", fileversion: "1",templatename:"DPC_MES_QC", author: 'Chayanika Halder', status: 'Stage 1 Pending', stage1approver: 'Abhisikta Bhattacharjee', stage2approver: 'Abhisikta Bhattacharjee', comments: 'hiii' },

  //     // ];

  //     // setRowData(data);
  // }, []);

  function fileDownload(data, type) {
    //console.log(data['data'])
    var payload = {
      actionId: data["data"]["action_id"].toString(),
      filename: data["data"]["fileName"].split(".xlsx")[0],
      fileType: type,
      partnnername: data["data"]["partner_name"],
      templatename: data["data"]["templateName"],
      role: localStorage.getItem("role"),
      id: user.user_id,
      partner: user.partner_name,
      email: user.email,
    };
    downloadFile(payload)
      .then((datas) => {
        //console.log(datas)
        if (datas.status === 200) {
          const url = window.URL.createObjectURL(new Blob([datas.data]));
          const link = document.createElement("a");
          //link.href = url;
          link.href = DOMPurify.sanitize(url);
          //console.log(url, link)
          // if (/^https?:\/\//.test(url)) {
          //   link.href = DOMPurify.sanitize(url);
          // } else {
          //   console.log("Invalid URL:");
          //   // Handle the invalid URL appropriately
          //   // For example, you might set link.href to a default safe URL or leave it unchanged
          // }
          if (type === "DQM") //type === DQM excel download
          {
            link.setAttribute("download", data["data"]["fileName"].split(".xlsx")[0] + "_Data_Quality_Report.xlsx");
          }
          else //type === excel and role is approver csv download , 
          //type === excel and role is author, supruser excel downlaod
          {
            if (localStorage.getItem("role") === "Author" || localStorage.getItem("role") === "Super User") {
              link.setAttribute("download", data["data"]["fileName"]);
            }
            else {
              link.setAttribute("download", data["data"]["fileName"].replace('.xlsx', '.csv'));
            }
            //Only change if the file is not DQM

          }

          //link.setAttribute("download", data["data"]["fileName"]);
          document.body.appendChild(link);
          //document.body.appendChild(DOMPurify.sanitize(link));
          link.click();
          document.body.removeChild(link);
          toast.success('Your request is being processed. Download will start automatically once processing is finished')

        }
        else {
          setErrormessage('Download process failed. Please try after sometime or contact the system administrator by emailing your sponsor');
          toast.error('Download process failed. Please try after sometime or contact the system administrator by emailing your sponsor')

        }




      })
      .catch((error) => {
       // console.log(error)

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          // else if (error.response.status === 400) {
          //   toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
          // }
          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {

            toast.error('Download process failed. Please try after sometime or contact the system administrator by emailing your sponsor')


          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }
        //setErrormessage('Download process failed. Please try after sometime or contact the system administrator by emailing your sponsor');
        //toast.error('Download process failed. Please try after sometime or contact the system administrator by emailing your sponsor')

        //console.error('There was an error!', error);
      });
  }



  const reUploadFile = (params) => {
    navigate("/upload", {
      state: {
        fileDetails: params["data"],
      },
    });
  }

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    rowData: rowData,
    cacheQuickFilter: true,
  };
  const gridOptionsstatus = {
    columnDefs: columnDefsstatus,
    defaultColDef: defaultColDef,
    rowData: rowDatastatus,
    cacheQuickFilter: true,
  };
  const fetchStatusViewData = (tab, page) => {
    //console.log(page)
    const user = JSON.parse(localStorage.getItem("userDetails"));
    fetchStatusView(userdetailsokta.userID, tab, user.partner_name)
      .then((datas) => {
        //console.log(datas)
        var temphistorydata = datas['data'];
        setRowData(temphistorydata);
        setLoading(false);
        //console.log(temphistorydata)
      })
      .catch((error) => {
        //console.log(error)
        //setErrormessage(error.toString());
        //toast.error(error.toString())
        // toggleShowErrorBox(true);
        //   setTimeout(() => {
        //     toggleShowErrorBox(false);
        //   }, 15000);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }

          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {
            toggleShowErrorBox(true);
            setErrormessage(error.toString());
            
            setTimeout(() => {
              toggleShowErrorBox(false);
            }, 15000);



          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }

        // console.error('There was an error!', error);
        setLoading(false);
      });
  };
  const fetchStatusViewDataStatus = (tab, page) => {
    //console.log(page)
    const user = JSON.parse(localStorage.getItem("userDetails"));
    fetchStatusView(userdetailsokta.userID, tab, user.partner_name)
      .then((datas) => {
        var temphistorydata = datas['data'];
        setRowDataStatus(temphistorydata);
        setLoading(false);
        //console.log(temphistorydata)
      })
      .catch((error) => {
       // console.log(error)
        
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }

          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {
            toggleShowErrorBox(true);
            setErrormessage(error.toString());
            toggleShowErrorBox(true);
            setTimeout(() => {
              toggleShowErrorBox(false);
            }, 15000);



          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }
        // setTimeout(() => {
        //   toggleShowErrorBox(false);
        // }, 15000);
        //toast.error(error.toString())

        //console.error('There was an error!', error);
        setLoading(false);
      });
  };

  const onFilterTextBoxChanged = useCallback(() => {
    // gridOptions.api.setQuickFilter(
    //     document.getElementById('filter-text-box').value
    // );
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
    // Check if there are any rows after applying the filter
    const filteredRowCount = gridRef.current.api.getDisplayedRowCount();
    // If there are no rows, show the no rows overlay
    if (filteredRowCount === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
  }, []);

  const resetSearch = () => {
    document.getElementById("filter-text-box").value = "";
    onFilterTextBoxChanged();
  };

  function viewData(data) {
    //console.log(data)
    // var requestid = data.requestId
    //console.log(requestid)
    setSeleteddata(data["data"]);
    setshowDQM((showDQM) => !showDQM);
    //return (<ShowDQMdetails dqmdata = {selectedata} showtem={showDQM} />);
  }

  function viewDataQuality(data) {
    //console.log(data)
    // var requestid = data.requestId
    //console.log(requestid)
    setDqmrejectdata(data["data"]);
    setshowDQMreject((showDQMreject) => !showDQMreject);

    //return (<ShowDQMdetails dqmdata = {selectedata} showtem={showDQM} />);
  }

  const onSelectionChanged = useCallback((event) => {
    //var selectedRows = gridRef.current.api.getSelectedRows();
    // console.log(gridRef.current.api.getSelectedRows())
    // console.log(gridOptions.api.getColumnDefs())
    // if ('getSelectedRows' in gridOptions.api)
    // {
    var selectedRows = gridRef.current.api.getSelectedRows();
    //console.log(gridRef.current.api.getColumnDefs())
    var getcoldef = gridRef.current.api.getColumnDefs();
    //console.log(getcoldef)
    var selectedRowsString = "";
    var maxToShow = 5;
    var selectedname = [];
    selectedRows.forEach(function (selectedRow, index) {
      if (index >= maxToShow) {
        return;
      }
      if (index > 0) {
        selectedRowsString += ", ";
      }
      selectedRowsString += selectedRow["filename"];
      selectedname.push(selectedRow);
    });
    setSeletedrowclickdata(selectedname);
    //console.log((selectedRows))

    //********************************* */
    gridRef.current.api.setColumnDefs(getcoldef);
    //  }
  }, []);

  function errorClass(error) {
    return error ? "is-invalid" : "";
  }

  let selectedTemplateArchive = (type, params) => {
    // console.log(params)
    if (type === "archive_rejected" || type === "archive_approve") {
      //alert(type)
      setApprovalStatus(type);
      setModalAppralOpen(true);
      setShowApprovalModal(true);
      setTemplateId(params["data"]["action_id"]);
    }
  };

  let selectedTemplate = (type) => {
    //alert('approve')

    //console.log(selecterowclickdata)
    //console.log(document.getElementsByClassName('comments_textarea')[0].value)
    //console.log(checkboxselect)
    if (selecterowclickdata.length === 0) {
      setErrormessage("Please select at least one checkbox");
      toggleShowErrorBox(true);
      setTimeout(() => {
        toggleShowErrorBox(false);
      }, 15000);
      return false;
    } else {
      fetchEsignURL()
        .then((response) => {
          // console.log("response", response);
          setEsignURL(response);
        })
        .catch((error) => {
          console.error("There was an error!");
        });
      setErrormessage("");
      let status = "";
      if (type === "approve") {
        status = "APPROVED";
        setApprovalStatus("APPROVED");
        // console.log(document.getElementsByClassName('check_lock')[0].value)
        if (checkboxselect === false) {
          setErrorcheck(true);
          return false;
        } else {
          setErrorcheck(false);
          seterrorcoments(false);
          setModalAppralOpen(true);
          setShowApprovalModal(true);
        }
      } else {
        if (
          document.getElementsByClassName("comments_textarea")[0].value === ""
        ) {
          setErrormessage(" *** Please add comment for rejection");
          toggleShowErrorBox(true);
          setTimeout(() => {
            toggleShowErrorBox(false);
          }, 15000);
          seterrorcoments(true);
          return false;
        }
        status = "REJECTED";
        setApprovalStatus("REJECTED");
        setErrorcheck(false);
        seterrorcoments(false);
        setModalAppralOpen(true);
        setShowApprovalModal(true);
        // const user = JSON.parse(localStorage.getItem("userDetails"));
        // var requests = [];

        // for (let row = 0; row < selecterowclickdata.length; row++) {
        //   requests.push({
        //     action_id: selecterowclickdata[row]["action_id"].toString(),
        //   });
        // }
        // var actionpayload = {
        //   partner: user.partner_name,
        //   id: user.user_id,
        //   requests: requests,
        //   action: "reject",
        //   role: localStorage.getItem("role"),
        // };

        // //console.log(actionpayload)

        // setModalAppralOpen(false);
        // setShowApprovalModal(false);
        // statusUpdate(actionpayload, approvalStatus)
        //   .then((datas) => {
        //     //console.log(datas)
        //     setErrorcheck(false);
        //     seterrorcoments(false);
        //     document.getElementsByClassName("check_lock")[0].checked = false;
        //     if (datas?.status === "success") {
        //       fetchStatusViewData("Actions");
        //       document.getElementsByClassName("comments_textarea")[0].value =
        //         "";
        //       setErrormessage("");

        //       setSuccessmessage(datas?.result);
        //       toast.success(datas?.result);
        //     } else {
        //       setSuccessmessage("");
        //       setErrormessage(datas?.result);
        //       //toast.error(datas?.result);
        //     }
        //   })
        //   .catch((error) => {
        //     setSuccessmessage("");
        //     setErrormessage(error.toString());
        //     //toast.error(error.toString())
        //     setErrorcheck(false);
        //     setModalAppralOpen(false);
        //     setShowApprovalModal(false);
        //     seterrorcoments(false);
        //     document.getElementsByClassName("check_lock")[0].checked = false;
        //     // console.error('There was an error!', error);
        //   });

      }
    }
  };

  let handleCloseApproval = () => {
    setShowApprovalModal(false);
    //localStorage.removeItem("eSignApproval");
    // setShow(false);
  };
  let handleShowApproval = () => {
    if (approvalStatus === "archive_rejected" || approvalStatus === "archive_approve") {
      approvalMethod();
    }
    else {
      if (esignURL) {
        //console.log(esignURL)
        setModalAppralOpen(false);
        setShowApprovalModal(false);
        setLoading(true);
        var url = esignURL
        var win = window.open(url, "thePopUp", "width=600,height=600");
        win.openedFromDisclaimer = true;
        //console.log(win)
        // win.localStorage.removeItem("hashToken");
        window.localStorage.setItem("eSignApproval", 'processing');
        window.localStorage.setItem("isapprovalpage", 'yes');
        var pollTimer = window.setInterval(function () {
          if (win.closed !== false) {
            window.clearInterval(pollTimer);
            console.log("closed");
            popupClosedApproval();
          }
        }, 200);
      }

    }


  };
  useEffect(() => {
    //console.log(userdetailsokta.userID)
    if (window.opener && userdetailsokta.userID) {
      console.log(userdetailsokta.userID)
    }

  }, [esignURL, userdetailsokta.userID]);

  const popupClosedApproval = () => {
    console.log("popup closed");
    // console.log(window.localStorage.getItem("eSignApproval"))

    if (window.localStorage.getItem("eSignApproval") === "done") {
      approvalMethod();

      window.localStorage.removeItem("eSignApproval");
      window.localStorage.removeItem("isapprovalpage");
    } else {
      setLoading(false);
      alert('E-Sign failed. Please try again');
    }
  };

  function approvalMethod() {
    setShowApprovalModal(true);
    const user = JSON.parse(localStorage.getItem("userDetails"));
    var requests = [];

    for (let row = 0; row < selecterowclickdata.length; row++) {
      requests.push({
        action_id: selecterowclickdata[row]["action_id"].toString(),
      });
    }
    if (approvalStatus === "APPROVED") {
      var actionpayload = {
        partner: user.partner_name,
        id: user.user_id,
        requests: requests,
        //action: (approvalStatus = "APPROVED")?"approve":"reject",
        action: "approve",
        role: localStorage.getItem("role"),
      };
    }
    else if (approvalStatus === "REJECTED") {
      var actionpayload = {
        partner: user.partner_name,
        id: user.user_id,
        requests: requests,
        //action: (approvalStatus = "APPROVED")?"approve":"reject",
        action: "reject",
        role: localStorage.getItem("role"),
        comments: document.getElementsByClassName("comments_textarea")[0].value,
      }

    }

    else {
      var actionpayload = {
        actionType:
          approvalStatus === "archive_approve"
            ? "archive_approved"
            : "archive_rejected",
        action_id: templateId.toString(),
        partner: user.partner_name,
        id: user.user_id,
        role: localStorage.getItem("role"),
      };
    }

    //console.log(actionpayload)
    //return false

    setModalAppralOpen(false);
    setShowApprovalModal(false);
    statusUpdate(actionpayload, approvalStatus)
      .then((datasres) => {
        //console.log(datas)
        setLoading(false);
        setErrorcheck(false);
        seterrorcoments(false);
        var datas = datasres.data;
        if (approvalStatus === "APPROVED" || approvalStatus === "REJECTED") {
          document.getElementsByClassName("check_lock")[0].checked = false;
        }
        if (datas?.status === "success") {
          fetchStatusViewData("Actions");
          if (approvalStatus === "APPROVED" || approvalStatus === "REJECTED") {
            document.getElementsByClassName("comments_textarea")[0].value = "";
          }
          setErrormessage("");

          setSuccessmessage(datas?.result);
          toast.success(datas?.result);
        } else {
          setSuccessmessage("");
          setErrormessage(datas?.result);
          //toast.error(datas?.result);
          toggleShowErrorBox(true);
          setTimeout(() => {
            toggleShowErrorBox(false);
          }, 15000);
        }
      })
      .catch((error) => {
        //console.log(error)
        setLoading(false);
        setSuccessmessage("");
        
        //toast.error(error.toString())

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          
          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {

            setErrormessage(error.toString());
            toggleShowErrorBox(true);
            setTimeout(() => {
              toggleShowErrorBox(false);
            }, 15000);
          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }

        setErrorcheck(false);
        setModalAppralOpen(false);
        setShowApprovalModal(false);
        seterrorcoments(false);
        if (approvalStatus === "APPROVED") {
          document.getElementsByClassName("check_lock")[0].checked = false;
        }
        //console.error('There was an error!', error);
      });
  }

  function viewFileDetails(data) {
    //console.log(data.data)
    setviewFileDetailsModal(true);
    setSeleteddata(data["data"]);
  }
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => 'No rows found at: ' + new Date().toLocaleTimeString(),
    };
  }, []);
  return (
    <div className="row approvalbackground1">
      <div className="col-12">
        <ApprovalMessage />
        <div
          className={` mediumtextformat  py-2  mx-auto`}
          style={{ width: "98%" }}
        >
          <div className="insideApproval">
            <div className="tabsection novborder">
              <nav
                className="navbar navbar-expand-md pb-0"
                style={{ paddingLeft: "4%" }}
              >
                <div
                  className="collapse navbar-collapse "
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav">
                    <li
                      className={`${activetab === true ? `active` : ""
                        }  nav-item `}
                    >
                      <Link
                        className="nav-link linkdec"
                        onClick={() => {
                          setActivetab(true);
                          setLoading(true);
                        }}
                      >
                        Action <span className="right-border pr-2"></span>
                      </Link>
                    </li>
                    <li
                      className={`${activetab === false ? `active` : ""
                        }  nav-item `}
                    >
                      <Link
                        className="nav-link linkdec"
                        onClick={() => {
                          setActivetab(false);
                          setLoading(true);
                        }}
                      >
                        Status View
                      </Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-md-4 col-lg-4 col-sm-8 pb-1 text-center"
                  style={{ minHeight: 30 }}
                >
                  <div
                    className="input-group searchBox"
                    style={{ width: "auto", border: "transparent" }}
                  >
                    <div className="input-group-prepend border-right-0">
                      <div
                        className="input-group-text bg-transparent "
                        id="btnGroupAddon2"
                      >
                        <i className="fa fa-search font-size-14"></i>
                      </div>
                    </div>
                    <input
                      className="form-control font-size-14 "
                      id="filter-text-box"
                      type="search"
                      placeholder="Search..."
                      onInput={onFilterTextBoxChanged}
                    />
                    <button
                      type="button"
                      onClick={resetSearch}
                      className="btn bg-transparent"
                      style={{ marginLeft: "-40px", zIndex: 100 }}
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </nav>
            </div>



            {/* {successmessage &&
                            <span className="text-success mb-3 globaltextformat ">{successmessage}</span>
                        } */}

            {showDQM && (
              <ShowDQMdetails
                dqmdata={selectedata}
                showtem={showDQM}
                setshowDQM={setshowDQM}
                type="viewdqm"
              />
            )}
            {showfile && (
              <ShowfileDetails
                dqmdata={selectedata}
                showfile={showfile}
                setviewFileDetailsModal={setviewFileDetailsModal}
              />
            )}
            {showDQMreject && (
              <ShowDQMReject
                dqmdata={dqmrejectdata}
                showtem={showDQMreject}
                setshowDQM={setshowDQMreject}
                type="reject"
              />
            )}
            {activetab && (
              <>
                <div className="pt-1" style={{ paddingLeft: "2%" }}>
                  <div
                    className="agGridWrapper datatablerefresh"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="ag-theme-alpine datatablerefresh"
                      style={{
                        height: checkbox ? "55vh" : "65vh",
                        width: "auto",
                      }}
                    >
                      <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        gridOptions={gridOptions}
                        onSelectionChanged={onSelectionChanged}
                        animateRows={true}
                        cacheQuickFilter={true}
                        pagination={true}
                        //paginationPageSize={2}
                        paginationAutoPageSize={true}
                        ref={gridRef}
                        rowSelection={"multiple"}
                        rowHeight={50}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                      //onGridReady={onGridReady}
                      ></AgGridReact>

                    </div>
                  </div>
                </div>
                {/* <div className="pb-1 align-right" >
                  <Stack spacing={2} >
                    <Pagination count={10} 
                    sx={{
                      '& .Mui-selected': {
                        backgroundColor: '#007bff', // Replace with your desired active color
                        color: '#ffffff', // Optional: text color for the active item
                      },
                      '& .MuiPaginationItem-root:hover': {
                        backgroundColor: '#007bff', // Replace with your desired hover color
                      },
                      '& .MuiPaginationItem-root:focus': {
                        outline: 'none', // Replace with your desired focus outline color
                      },
                    }}
                     showFirstButton showLastButton onChange={handleChangePagination} />

                  </Stack>

                </div> */}
                {checkbox && (
                  <div className="row" style={{ paddingLeft: "2%" }}>
                    <div className="col-sm-4">
                      <div className="form-group pt-1 ">
                        <textarea
                          className={`form-control comments_textarea ${errorClass(
                            errorcoments
                          )}`}
                          rows="2"
                          cols="30"
                          placeholder="Comments"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-check">
                        <input
                          onClick={(event) => {
                            setCheckboxselect(event.target.checked);
                          }}
                          id="check_lock"
                          className={`form-check-input check_lock ${errorClass(
                            errorstate
                          )}`}
                          type="checkbox"
                          name="check_lock"
                          value="lock"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          <i>
                            Reviewed and 100% data verified.
                          </i>
                        </label>
                      </div>

                      {/* <div className="form-group">
                        {errormessage && (
                          <span
                            className={` ${Accounttemplatestyle.mandetoryfield}`}
                          >
                            {errormessage}
                          </span>
                        )}
                      </div> */}
                      {/* {errormessage && (
                        <div className="alert alert-danger" role="alert">
                          <i className="fa fa-exclamation-circle mr-2" style={{ color: 'red', fontSize: '19px' }}></i>{errormessage}
                        </div>
                      )} */}
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group text-right pt-3 ">
                        <button
                          type="button"
                          onClick={() => selectedTemplate("approve")}
                          className={`btn btn-primary text-wrap fw-normal submit_btnapp`}
                        >
                          <i class="fa fa-check"></i> Approve
                        </button>

                        <button
                          type="button"
                          onClick={() => selectedTemplate("reject")}
                          className="btn   text-wrap fw-normal submit-reset"
                          id="submit-reset"
                          style={{
                            margin: "0 10px",
                            backgroundColor: "#ba6868",
                            color: "white",
                          }}

                        >
                          <i class="mr-1 fa fa-close"></i>Reject
                          {/* <div>
                            <span>
                              <X size="17" />
                            </span>
                            <span>Reject</span>
                          </div> */}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/* {
                  !checkbox &&
                  (
                    <div className="row" style={{ paddingLeft: "2%" }}>
                    {errormessage && (
                      <div className="alert alert-danger" role="alert">
                        <i className="fa fa-exclamation-circle mr-2" style={{ color: 'red', fontSize: '19px' }}></i>{errormessage}
                      </div>
                    )}
                    </div>
                  )
                 
                  
                }
                 */}

              </>
            )}
            {!activetab && (
              <>
                <div className="pt-1" style={{ paddingLeft: "2%" }}>
                  <div
                    className="agGridWrapper  datatablerefresh"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="ag-theme-alpine  datatablerefresh datastatus"
                      style={{ height: "65vh", width: "auto", padding: "12px" }}
                    >
                      <AgGridReact
                        rowData={rowDatastatus}
                        columnDefs={columnDefsstatus}
                        defaultColDef={defaultColDef}
                        gridOptions={gridOptionsstatus}
                        animateRows={true}
                        cacheQuickFilter={true}
                        pagination={true}
                        //paginationPageSize={2}
                        paginationAutoPageSize={true}
                        ref={gridRef}
                        rowHeight={50}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                      //onGridReady={onGridReady}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
                {/* <div className="pb-1 align-right" >
                  <Stack spacing={2} >
                    <Pagination count={50} sx={{
                      '& .Mui-selected': {
                        backgroundColor: '#007bff', // Replace with your desired active color
                        color: '#ffffff', // Optional: text color for the active item
                      },
                      '& .MuiPaginationItem-root:hover': {
                        backgroundColor: '#007bff', // Replace with your desired hover color
                      },
                      '& .MuiPaginationItem-root:focus': {
                        outline: 'none', // Replace with your desired focus outline color
                      },
                    }} showFirstButton showLastButton onChange={handleChangePagination} />

                  </Stack>

                </div> */}
              </>
            )}



            {loading && <Loader />}

            {modalAppralOpen && (
              <div
                className={
                  "modal fade bd-example-modal-lg" +
                  (show ? " show d-block" : " d-none")
                }
                // tabIndex="-1"
                role="dialog"
                style={{
                  position: "fixed",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  background: "rgb(182 184 202/0.5)",
                  //opacity: "0.5",
                  paddingTop: "12%",
                }}
              >
                <div className="modal-dialog " role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {approvalStatus === "APPROVED" && (
                          <p>Proceed with Approval?</p>
                        )}
                        {approvalStatus === "REJECTED" && (
                          <p>Proceed with Rejection?</p>
                        )}
                        {approvalStatus === "archive_approve" && (
                          <p>Proceed with Archiving?</p>
                        )}
                        {approvalStatus === "archive_rejected" && (
                          <p>Proceed with Archiving?</p>
                        )}

                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseApproval}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      UserId : {JSON.parse(localStorage.getItem("userDetails"))["user_id"]}
                      {/* {
                        JSON.parse(localStorage.getItem("userDetails"))[
                        "user_id"
                        ]
                      } */}
                      {/* <p>Do you want to {approvalStatus === 'archive_approve' ? 'Archive approve' : 'Archive reject'} this template?</p> */}
                      {approvalStatus === "APPROVED" && (
                        <p>Do you want to approve this template?</p>
                      )}
                      {approvalStatus === "REJECTED" && (
                        <p>Do you want to reject this template?</p>
                      )}
                      {approvalStatus === "archive_approve" && (
                        <p>Are you sure you want to archive this file?</p>
                      )}
                      {approvalStatus === "archive_rejected" && (
                        <p>Are you sure you want to archive this file?</p>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleShowApproval}
                      >
                        Ok
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={handleCloseApproval}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showErrorBox && <div className="errorBox">❗ {errormessage}</div>}
            <Toaster
              position="top-right"
              reverseOrder={false}
              toastOptions={{
                className: "",
                duration: 5000,
                error: {
                  icon: "❗",
                  style: {
                    color: "red",
                  },
                },
                success: {
                  duration: 5000,
                  style: {
                    color: "green",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabcommon;
